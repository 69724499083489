import { css } from 'styled-components';

/**
 * All breakpoint keys
 * Mainly used keys are:
 * xs: mobile
 * md: tablet
 * lg: pc
 */
const shortBreakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;

export type ShortBreakpointKey = typeof shortBreakpointKeys[number];

export type PropByBreakpointKey<T> = Partial<Record<ShortBreakpointKey, T>>;

export type ResponsiveStyleProp<T> = Exclude<T, Record<ShortBreakpointKey, unknown>> | PropByBreakpointKey<T>;

/**
 * Covert a responsive prop object into an array of css style
 *
 * e.g.
 * mapResponsiveStyleProp({ xs: 'inline', md: 'flex', lg: 'block' }, (display) => css`disolay: ${display}`);
 *
 * maps to
 *
 * css`
 *  display: inline;
 *
 *  @media (min-width: 700 px) {
 *    display: flex;
 *  }
 *
 *  @media (min-width: 1200 px) {
 *    display: block;
 *  }
 * `
 *
 */
export const mapResponsiveStyleProp = <T>(
  responsiveStyleProp: ResponsiveStyleProp<T>,
  iterator: (prop: T, breakpointKey: ShortBreakpointKey, index: number) => ReturnType<typeof css> | string
) => {
  const propByBreakpointKey =
    typeof responsiveStyleProp === 'object'
      ? (responsiveStyleProp as PropByBreakpointKey<T>)
      : ({ xs: responsiveStyleProp } as PropByBreakpointKey<T>);

  return css`
    ${({ theme }) =>
      shortBreakpointKeys
        .map((key, index) => {
          const responsivePropValue = propByBreakpointKey[key];
          const breakpointValue: number | undefined = theme.styledBootstrapGrid.breakpoints?.[key];

          if (typeof responsivePropValue === 'undefined' || typeof breakpointValue === 'undefined') return null;

          const cssStyle = iterator(responsivePropValue, key, index);

          if (key === 'xs') {
            return cssStyle;
          }

          return css`
            @media (min-width: ${breakpointValue}px) {
              ${cssStyle}
            }
          `;
        })
        .filter((x) => x)}
  `;
};
