import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { repeatNTimes } from '../Carousel';

import * as S from './styled.LogoList';
import type { LogoListProps } from './types.LogoList';

export const LogoList: FC<LogoListProps> = (props) => {
  const {
    logos,
    times = 2,
    ssgLimit = 10,
    borderBottom = true,
    animated = {
      xs: logos.length > 2,
      md: logos.length > 6,
      lg: logos.length > 8,
    },
    ...restProps
  } = props;
  const [limit, setLimit] = useState(ssgLimit);

  const repeatedLogos = repeatNTimes(logos, Math.ceil(times / 2) * 2);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLimit(Infinity);
    }, 3000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <S.LogoList logoCount={repeatedLogos.length} borderBottom={borderBottom} animated={animated} {...restProps}>
      <div className="LogoList-content">
        {repeatedLogos.map(({ name, url, repeatIndex }, index) => (
          <div key={index} className="LogoList-item" data-repeated={repeatIndex > 0}>
            {index < limit && <img className="LogoList-item-img" src={url} alt={name} />}
          </div>
        ))}
      </div>
    </S.LogoList>
  );
};
