import flatMap from 'lodash/flatMap';
import range from 'lodash/range';

/**
 * Repeat the array for n times
 * @param items T[]
 * @param N number
 * refer: https://github.com/lodash/lodash/issues/1436#issuecomment-792902387
 */
export const repeatNTimes = <T = unknown>(items: T[], N: number): Array<T & { repeatIndex: number }> =>
  flatMap(range(N), (repeatIndex) =>
    items.map((item) => ({
      ...item,
      repeatIndex,
    }))
  );

/**
 * Repeat the array for 3 times
 */
export const repeat3Times = <T = unknown>(items: T[]): Array<T & { repeatIndex: number }> => repeatNTimes(items, 3);
