export enum Breakpoint {
  desktopExtraLarge = 1920,
  desktopLarge = 1600,
  desktop = 1440,
  desktopSmall = 1200,
  desktopExtraSmall = 1000,
  ipad = 700,
  iphone = 375,
  iphoneSmall = 1,
}

// This is the default zIndex variable from mui
// Explicitly declared here to reuse in our own code.
// https://material-ui.com/customization/z-index/
export const zIndex = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

/**
 * keep string and turn number into css property with unit
 * @param numOrStr
 * @param unitOrFunc
 * @returns
 */
export const autoAddUnit = (
  numOrStr: number | string | undefined,
  unitOrFunc: string | ((num: number) => string | number) = 'px'
) => {
  if (typeof numOrStr !== 'number') return numOrStr;

  return typeof unitOrFunc === 'function' ? unitOrFunc(numOrStr) : `${numOrStr}${unitOrFunc}`;
};
