import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { mapResponsiveStyleProp, autoAddUnit } from '../themes';

import type { LogoListStyleProps } from './types.LogoList';

export const LogoList = styled.div<LogoListStyleProps>`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    ${({ maskOpacity = 0 }) => `opacity: ${maskOpacity};`}
    z-index: 9;
  }
  width: 100%;
  ${({ theme, borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid ${theme.color.GrayscaleLine};
    `};

  max-height: 96px;
  min-height: 48px;

  height: 48px;
  ${media.md`
    height: 5vw;
  `}

  position: relative;
  overflow: hidden;

  .LogoList-content {
    position: absolute;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .LogoList-item {
      margin-left: 8px;
      ${({ logoHeight = '50%' }) => `height: ${autoAddUnit(logoHeight)};`}
      min-width: 125px;
      max-width: 250px;

      ${({ logoWidth = { xs: '125px', md: '12.5vw' } }) => mapResponsiveStyleProp(logoWidth, (x) => `width: ${x};`)}

      display: flex;
      justify-content: center;

      .LogoList-item-img {
        height: 100%;
        max-width: 100%;
        width: auto;
        object-fit: contain;
        filter: grayscale(1);
      }
    }

    /** Animation styles are below */
    animation-timing-function: linear;
    ${({ animated }) =>
      animated &&
      mapResponsiveStyleProp(
        animated,
        (x) =>
          css`
            animation-name: ${x ? 'logo-list-infinite-scroll' : 'none'};
            .LogoList-item[data-repeated='true'] {
              display: ${x ? 'flex' : 'none'};
            }

            left: ${x ? '0' : '50%'};
            transform: ${x ? 'none' : 'translateX(-50%)'};
          `
      )}
    animation-iteration-count: infinite;

    ${({ logoCount, animationTime = { xs: 0.75, md: 1 } }) =>
      animationTime != null && mapResponsiveStyleProp(animationTime, (x) => `animation-duration: ${logoCount * x}s;`)}
  }

  @keyframes logo-list-infinite-scroll {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-50%, 0, 0);
    }
  }
`;
